export enum TASK_ALERT_STATUS {
  TRAIN = 'TRAIN',
  TEST = 'TEST',
  EXTRACT_DATA = 'EXTRACT_DATA',
  VALIDATE_DATA = 'VALIDATE_DATA',
  SORT_DATA = 'SORT_DATA',
  DESCRIBE_DATA = 'DESCRIBE_DATA',
  PREPROCESS_DATA = 'PREPROCESS_DATA',
  CREATE_EXPERIMENT_PATH = 'CREATE_EXPERIMENT_PATH',
  CREATE_SEGMENTS = 'CREATE_SEGMENTS',
  FILE_UPLOAD = 'FILE_UPLOAD',
  PREPARE_DASHBOARD = 'PREPARE_DASHBOARD',
  SUCCESS = 'SUCCESS',
}

export type AlertLogType = {
  id: number
  projectId: number
  status: TASK_ALERT_STATUS
  infoLog: string
  userCheck: boolean
  createdAt: string
  updatedAt: string
}

export type AlertLogDictType = { [id: number]: AlertLogType }
